    @import '../../../../variables/variables.scss';
    .tgd__container__result {
        height: 99%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .tgd__main__result {
            height: 100%;
            .tgd__container__body {
                height: 100%;
            }
        }
        &__title {
            h2 {
                text-align: center;
            }
        }
        &__resultPage {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px 0;
            img {
                height: 100%;
                position: absolute;
                object-fit: contain;
                z-index: 1;
            }
            &__circle {
                width: 150px;
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 14px solid;
                border-radius: 50%;
                padding: 20px;
                margin: 20px;
                z-index: 100;
                h1 {
                    font-size: 44px;
                    color: rgb(105, 105, 105);
                }
            }
        }
        &__description {
            display: flex;
            justify-content: center;
            p {
                max-height: 200px;
                max-width: 100%;
                color: $textColor;
                line-height: 26px;
                text-align: center;
                overflow: auto;
                background: /* Shadow covers */
                linear-gradient(rgb(104, 104, 104) 0%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), rgb(116, 116, 116) 70%) 0 100%, /* Shadows */
                radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
                background: /* Shadow covers */
                linear-gradient($backgroundGrey 10%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), $backgroundGrey 10%) 0 100%, /* Shadows */
                radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.089), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)) 0 100%;
                background-repeat: no-repeat;
                background-color: $backgroundGrey;
                background-size: 100% 40px, 100% 40px, 0% 0px, 100% 14px;
                /* Opera doesn't support this in the shorthand */
                background-attachment: scroll, local, scroll, scroll;
            }
        }
    }