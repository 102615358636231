@import '../../../variables/variables.scss';
.tgd__main {
    width: 70%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: $backgroundGrey;
    box-shadow: $shadowBasic;
    box-sizing: border-box;
    padding: 40px;
    z-index: 10;
    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        height: auto;
    }
    &__body {
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        height: 100%;
    }
}

@media screen and (max-width: 769px) {
    .tgd__main {
        width: 90%;
        height: 80%;
        margin-top: 60px;
        padding: 20px;
        box-sizing: border-box;
        &__header {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__body {
            width: 100%;
            box-sizing: border-box;
            padding: 0px;
            padding-top: 20px;
            padding-bottom: 20px;
            height: 86%;
            overflow: auto;
        }
    }
}