@import '../../../../variables/variables.scss';
.tgd__buttonBack__wrap {
    display: flex;
    align-content: center;
    cursor: pointer;
    .arrow-buttonBack {
        font-size: 18px;
    }
    h6 {
        padding: 0;
        margin: 0;
        margin-left: 4px;
        display: flex;
        align-items: center;
        color: $greyLight
    }
}

@media screen and (max-width: 769px) {
    .tgd__buttonBack__wrap {
        .arrow-buttonBack {
            font-size: 24px;
            border: 1px solid $greyLight;
            border-radius: 50%;
            padding: 2px;
        }
        h6 {
            display: none;
        }
    }
}