@import '../../../../variables/variables.scss';
.container__tgd__header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__progress {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tgd__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        &__logo {
            width: 30%;
            display: flex;
            justify-content: flex-start;
            img {
                max-height: 30px
            }
        }
        ;
        &__title {
            width: 40%;
            color: $colorPrimary;
            text-transform: uppercase;
            font-family: 'Bakbak One', cursive;
            letter-spacing: 4px
        }
        ;
        &__data {
            width: 30%;
            display: flex;
            justify-content: flex-end;
            span {
                color: $textColor;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .container__tgd__header {
        &__progress {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
        .tgd__header {
            width: 100%;
            height: auto;
            &__logo {
                width: 40%;
                img {
                    max-height: 22px
                }
            }
            ;
            &__title {
                width: 60%;
                display: flex;
                justify-content: flex-end;
                text-transform: uppercase
            }
            ;
            &__data {
                width: 100%;
                display: none;
            }
        }
    }
}