.tgd__app {
  text-align: center;
}

.color__title {
  color: #606060;
}

.bold {
  font-weight: bold !important;
}
