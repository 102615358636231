.tgd__container__result {
  height: 99%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.tgd__container__result .tgd__main__result {
  height: 100%;
}

.tgd__container__result .tgd__main__result .tgd__container__body {
  height: 100%;
}

.tgd__container__result__title h2 {
  text-align: center;
}

.tgd__container__result__resultPage {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 20px 0;
}

.tgd__container__result__resultPage img {
  height: 100%;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1;
}

.tgd__container__result__resultPage__circle {
  width: 150px;
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 14px solid;
  border-radius: 50%;
  padding: 20px;
  margin: 20px;
  z-index: 100;
}

.tgd__container__result__resultPage__circle h1 {
  font-size: 44px;
  color: dimgray;
}

.tgd__container__result__description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.tgd__container__result__description p {
  max-height: 200px;
  max-width: 100%;
  color: #606060;
  line-height: 26px;
  text-align: center;
  overflow: auto;
  background: -webkit-gradient(linear, left top, left bottom, from(#686868), to(rgba(255, 255, 255, 0))), -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(70%, #747474)) 0 100%, radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background: linear-gradient(#686868 0%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #747474 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #F7F7F7), to(rgba(255, 255, 255, 0))), -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(10%, #F7F7F7)) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.089), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;
  background: linear-gradient(#F7F7F7 10%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #F7F7F7 10%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.089), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: #F7F7F7;
  background-size: 100% 40px, 100% 40px, 0% 0px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: scroll, local, scroll, scroll;
}
