.container__tgd__header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container__tgd__header__progress {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container__tgd__header .tgd__header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 80px;
}

.container__tgd__header .tgd__header__logo {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.container__tgd__header .tgd__header__logo img {
  max-height: 30px;
}

.container__tgd__header .tgd__header__title {
  width: 40%;
  color: #423149;
  text-transform: uppercase;
  font-family: 'Bakbak One', cursive;
  letter-spacing: 4px;
}

.container__tgd__header .tgd__header__data {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.container__tgd__header .tgd__header__data span {
  color: #606060;
}

@media screen and (max-width: 769px) {
  .container__tgd__header__progress {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .container__tgd__header .tgd__header {
    width: 100%;
    height: auto;
  }
  .container__tgd__header .tgd__header__logo {
    width: 40%;
  }
  .container__tgd__header .tgd__header__logo img {
    max-height: 22px;
  }
  .container__tgd__header .tgd__header__title {
    width: 60%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    text-transform: uppercase;
  }
  .container__tgd__header .tgd__header__data {
    width: 100%;
    display: none;
  }
}
