.tgd__home {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 60px;
    &__img {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        object-fit: cover;
    }
    &__overlay {
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.192);
    }
}