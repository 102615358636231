.tgd__progressWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &__progressLine {
        width: 70%;
    }
}

@media screen and (max-width: 769px) {
    .tgd__progressWrap {
        margin-top: 10px;
        h6 {
            margin: 0;
            padding: 0;
        }
        &__progressLine {
            width: 50%;
        }
    }
}