/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

button {
    text-transform: capitalize!important;
    height: 48px;
    width: 300px;
    font-size: 20px!important;
    font-size: medium;
    z-index: 100;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    overflow: hidden;
    box-sizing: border-box;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 15px 0px;
    font-weight: bold;
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 16px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

a {
    text-decoration: none!important;
}

p {
    font-size: 16px;
    margin-top: 20px;
}

@media screen and (max-width: 769px) {
    h1 {
        font-size: 18px;
        line-height: 24px;
    }
    h2 {
        font-size: 16px;
        line-height: 20px;
    }
    h3 {
        font-size: 14px;
        line-height: 20px;
    }
    h4 {
        font-size: 14px;
        line-height: 18px;
    }
    h5 {
        font-size: 12px;
        line-height: 16px;
    }
    h6 {
        font-size: 10px;
        line-height: 14px;
    }
}