@import '../../../../variables/variables.scss';
.tgd__intro__body p {
    overflow: auto;
    margin-top: 0;
    height: 100%;
    box-sizing: border-box;
    background: /* Shadow covers */
    linear-gradient(rgb(104, 104, 104) 0%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), rgb(116, 116, 116) 70%) 0 100%, /* Shadows */
    radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */
    linear-gradient($backgroundGrey 10%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), $backgroundGrey 10%) 0 100%, /* Shadows */
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.089), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: $backgroundGrey;
    background-size: 100% 40px, 100% 40px, 0% 0px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: scroll, local, scroll, scroll;
}