.container__tgd__question {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container__tgd__question .container__tgd__buttonBack {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container__tgd__question__main {
  width: 70%;
  height: 85%;
  padding-top: 20px;
}

.container__tgd__question__button {
  width: 90%;
  height: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container__tgd__question .tgd__step__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: auto;
}

.container__tgd__question .tgd__step__title h2 {
  margin: 0;
  padding: 0;
  padding-bottom: 2px;
  line-height: 24px;
  text-align: start;
  margin-top: 10px;
}

.container__tgd__question .tgd__step__title h2 span {
  color: #099C32;
}

.container__tgd__question .tgd__intro__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 10%;
}

.container__tgd__question .tgd__intro__title h2 {
  width: 100%;
  margin: 0;
  padding: 0;
}

.container__tgd__question .tgd__step__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 80%;
  padding-top: 20px;
}

.container__tgd__question .tgd__intro__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 80%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media screen and (max-width: 769px) {
  .container__tgd__question .container__tgd__buttonBack {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .container__tgd__question__main {
    width: 100%;
    padding-top: 10px;
  }
  .container__tgd__question__button {
    width: 100%;
  }
  .container__tgd__question__button button {
    width: 70%;
    font-size: 18px !important;
  }
  .container__tgd__question .tgd__step__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
  }
  .container__tgd__question .tgd__step__title h2 {
    max-width: 100%;
    text-align: start;
    margin-top: 4px;
  }
}
