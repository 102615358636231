.tgd__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.tgd__form__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.tgd__form__list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 49%;
  height: 50px;
  margin-bottom: 20px;
  text-align: start;
  border-radius: 4px;
}

.tgd__form__list li .error {
  background-color: rgba(255, 0, 0, 0.199) !important;
}

@media screen and (max-width: 769px) {
  .tgd__form__list {
    width: 100%;
    overflow: auto;
  }
  .tgd__form__list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 44px;
    margin-bottom: 20px;
    text-align: start;
    border-radius: 4px;
  }
}
