.tgd__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        li {
            display: flex;
            align-items: center;
            width: 49%;
            height: 50px;
            margin-bottom: 20px;
            text-align: start;
            border-radius: 4px;
            .error {
                background-color: rgba(255, 0, 0, 0.199)!important;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .tgd__form {
        &__list {
            width: 100%;
            overflow: auto;
            li {
                display: flex;
                align-items: center;
                width: 100%;
                height: 44px;
                margin-bottom: 20px;
                text-align: start;
                border-radius: 4px;
            }
        }
    }
}