.tgd__progressWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.tgd__progressWrap__progressLine {
  width: 70%;
}

@media screen and (max-width: 769px) {
  .tgd__progressWrap {
    margin-top: 10px;
  }
  .tgd__progressWrap h6 {
    margin: 0;
    padding: 0;
  }
  .tgd__progressWrap__progressLine {
    width: 50%;
  }
}
