.tgd__intro__body p {
  overflow: auto;
  margin-top: 0;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: -webkit-gradient(linear, left top, left bottom, from(#686868), to(rgba(255, 255, 255, 0))), -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(70%, #747474)) 0 100%, radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background: linear-gradient(#686868 0%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #747474 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #F7F7F7), to(rgba(255, 255, 255, 0))), -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(10%, #F7F7F7)) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.089), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;
  background: linear-gradient(#F7F7F7 10%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #F7F7F7 10%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.089), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: #F7F7F7;
  background-size: 100% 40px, 100% 40px, 0% 0px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: scroll, local, scroll, scroll;
}
