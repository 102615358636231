// color
$colorPrimary: #423149;
$colorButton: #1073E7;
$greenColor: #099C32;
$redColor: #D31313;
$textColor: #606060;
$greyLight: #B4B4B4;
$greyExtraLight: #ECECEC;
$backgroundGrey: #F7F7F7;
// shadow
$shadowBasic: 0 10px 20px rgba(0, 0, 0, 0.19),
0 6px 6px rgba(0, 0, 0, 0.23);