.tgd__main {
  width: 70%;
  height: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 20px;
  background: #F7F7F7;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px;
  z-index: 10;
}

.tgd__main__header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: auto;
}

.tgd__main__body {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 20px;
  height: 100%;
}

@media screen and (max-width: 769px) {
  .tgd__main {
    width: 90%;
    height: 80%;
    margin-top: 60px;
    padding: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .tgd__main__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .tgd__main__body {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 86%;
    overflow: auto;
  }
}
