.tgd__body {
  height: 100%;
}

h2 {
  color: #606060;
  margin-top: 0;
  padding-top: 0;
}

p {
  font-size: 16px;
  line-height: 20px;
  color: #606060;
}

p b {
  font-weight: bold;
}

@media screen and (max-width: 769px) {
  h2 {
    color: #606060;
    margin-top: 0;
    padding-top: 0;
    font-size: 16px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: #606060;
  }
  .css-ahj2mt-MuiTypography-root {
    font-size: 14px !important;
    text-align: start;
  }
}
