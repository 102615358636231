@import '../../../../variables/variables.scss';
.tgd__body {
    height: 100%;
}

// proprietà generiche questionario
h2 {
    color: $textColor;
    margin-top: 0;
    padding-top: 0;
}

p {
    font-size: 16px;
    line-height: 20px;
    color: $textColor;
    // I titoli bold che arrivano dal Json vanno wrappati in un tag b.
    b {
        font-weight: bold;
    }
}

@media screen and (max-width: 769px) {
    h2 {
        color: $textColor;
        margin-top: 0;
        padding-top: 0;
        font-size: 16px;
    }
    p {
        font-size: 14px;
        line-height: 18px;
        color: $textColor;
    }
    .css-ahj2mt-MuiTypography-root {
        font-size: 14px!important;
        text-align: start;
    }
}