@import '../../../variables/variables.scss';
.container__tgd__question {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .container__tgd__buttonBack {
        width: 70%;
        display: flex;
        justify-content: space-between;
    }
    &__main {
        width: 70%;
        height: 85%;
        padding-top: 20px;
    }
    &__button {
        width: 90%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tgd__step__title {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: auto;
        h2 {
            margin: 0;
            padding: 0;
            padding-bottom: 2px;
            line-height: 24px;
            text-align: start;
            margin-top: 10px;
            span {
                color: $greenColor;
            }
        }
    }
    .tgd__intro__title {
        display: flex;
        align-items: flex-start;
        height: 10%;
        h2 {
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }
    .tgd__step__body {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        height: 80%;
        padding-top: 20px;
    }
    .tgd__intro__body {
        display: flex;
        height: 80%;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 769px) {
    .container__tgd__question {
        .container__tgd__buttonBack {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &__main {
            width: 100%;
            padding-top: 10px;
        }
        &__button {
            width: 100%;
            button {
                width: 70%;
                font-size: 18px!important;
            }
        }
        .tgd__step__title {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            height: auto;
            h2 {
                max-width: 100%;
                text-align: start;
                margin-top: 4px;
            }
        }
    }
}