@import '../variables/variables.scss';
.tgd__app {
    text-align: center;
}

// Global Classes
.color__title {
    color: $textColor;
}

.bold {
    font-weight: bold!important;
}

// Page survay css
@import '../../../variables/variables.scss';
.tgd__intro {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    flex-direction: column;
    &__title {
        width: 100%;
        text-align: start;
        height: 10%;
    }
    &__text {
        height: 80%;
        color: $textColor;
        line-height: 26px;
        text-align: start;
        overflow: auto;
        padding-bottom: 40px;
        background: /* Shadow covers */
        linear-gradient(red 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), red 70%) 0 100%, /* Shadows */
        radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
        background: /* Shadow covers */
        linear-gradient($backgroundGrey 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), $backgroundGrey 70%) 0 100%, /* Shadows */
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.089), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
        background-repeat: no-repeat;
        background-color: $backgroundGrey;
        background-size: 100% 40px, 100% 40px, 0% 0px, 100% 14px;
        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }
    &__button {
        height: 10%;
        padding-top: 0px;
    }
}

@media screen and (max-width: 769px) {
    .tgd__intro {
        &__body {
            max-width: 100%;
        }
    }
}