.tgd__buttonBackChildren__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  cursor: pointer;
}

.tgd__buttonBackChildren__wrap .arrow-buttonBack {
  font-size: 18px;
}

.tgd__buttonBackChildren__wrap h6 {
  padding: 0;
  margin: 0;
  margin-left: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #B4B4B4;
}

@media screen and (max-width: 769px) {
  .tgd__buttonBackChildren__wrap .arrow-buttonBack {
    font-size: 24px;
    border: 1px solid #B4B4B4;
    border-radius: 50%;
    padding: 2px;
  }
  .tgd__buttonBackChildren__wrap h6 {
    display: none;
  }
}
